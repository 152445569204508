<template>
  <page-section class="page-section_light project-slider">
    <template #content>
      <div class="container">
        <div class="wrapper">
          <h1
            class="title animation"
            v-observe-visibility="{ callback: animation, once: true}"
          >{{ data.title }}</h1>

          <!-- Image slider -->
          <div
            class="image-slider animation"
            v-observe-visibility="{ callback: animation, once: true}"
          >
            <Swiper
              @swiper="swiperImage = $event"
              :touch="false"
              :simulateTouch="false"
              :controller="{ control: swiperText }"
            >
              <SwiperSlide
                v-for="(slide, slideKey) in data.slides"
                :key="`image-${slideKey}`"
              >
                <video
                  v-if="slide?.Image?.mime.includes('video')"
                  :src="getUrl(slide?.Image.url)"
                  controls
                />
                <img
                  v-if="slide?.Image?.mime.includes('image')"
                  :src="getUrl(slide?.Image.url)"
                  :alt="data.title"
                >
              </SwiperSlide>
            </Swiper>
          </div>

          <!-- Bottom -->
          <div class="bottom">
            <!-- Slider controls -->
            <div class="slider-controls">
              <div
                class="controls-title animation"
                v-observe-visibility="{ callback: animation, once: true}"
              >{{ data.sliderTitle }}</div>
              <SliderArrows
                class="arrows"
                :swiper="swiperImage"
                :text="swiperImage?.activeIndex ? data?.slides[swiperImage?.activeIndex]?.NavigationText : '1'"
                theme="dark"
              />
            </div>

            <!-- Texts -->
            <div
              class="text-slider animation"
              v-observe-visibility="{ callback: animation, once: true}"
            >
              <Swiper
                @swiper="swiperText = $event"
                :touch="false"
                :simulateTouch="false"
                :controller="{ control: swiperImage }"
              >
                <SwiperSlide
                  v-for="(slide, slideKey) in data.slides"
                  :key="`text-${slideKey}`"
                >
                  <div class="text">{{ slide?.Text }}</div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </template>
  </page-section>
</template>

<script>
import PageSection from '@/components/PageSection.vue';

// Swiper
import SwiperCore, { Controller } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import SliderArrows from '@/components/SliderArrows.vue';
import { animationMixin } from '@/components/mixins/animation';

// API
import getUrl from '@/tools/getUrl';

SwiperCore.use([Controller]);

export default {
  name: 'ProjectSlider',
  mixins: [animationMixin],
  components: {
    SliderArrows,
    PageSection,
    SwiperSlide,
    Swiper,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      swiperImage: null,
      swiperText: null,
    };
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.project-slider {
  align-items: center;
  min-height: 100vh;

  @include wRule(
    padding,
    (
      xl: 270 0 90,
      lg: 209 0 113,
      md: 163 0 62,
      sm: 158 0 105,
    )
  );

  .title {
    font-weight: 700;
    line-height: 1.2;

    @include wRule(
      font-size,
      (
        xl: 65,
        lg: 50,
        md: 45,
        sm: 65,
      )
    );
    @include wRule(
      margin-bottom,
      (
        xl: 134,
        lg: 51,
        md: 32,
        sm: 65,
      )
    );
  }

  .wrapper {
    @include wRule(
      max-width,
      (
        xl: 450,
        lg: 400,
        md: 100%,
      )
    );
    @include wRule(
      padding-right,
      (
        xl: 130,
        lg: 70,
        md: 0,
      )
    );
  }

  .image-slider {
    position: relative;

    @include screen(md, min) {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }

    @include wRule(
      width,
      (
        xl: 1100,
        lg: 730,
        md: 100%,
      )
    );
    @include wRule(
      height,
      (
        md: 335,
        sm: 370,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(20px, 0, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.5s, transform 0.5s ease 0.5s;
    }

    .swiper-container {
      width: 100%;
      height: 100%;
    }

    .swiper-slide {
      overflow: hidden;

      video {
        @include fill-img;
      }

      img {
        @include fill-img;
      }
    }
  }

  /*
  Navigation
   */
  .arrows {
    display: inline-flex;
  }

  .controls-title {
    line-height: 1.28;
    font-weight: 700;

    @include wRule(
      font-size,
      (
        xl: 30,
        lg: 25,
        md: 20,
        sm: 45,
      )
    );
    @include wRule(
      margin-bottom,
      (
        xl: 38,
        lg: 29,
        md: 13,
        sm: 0,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.6s, transform 0.5s ease 0.6s;
    }
  }

  .slider-controls {
    @include screen(sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  /*
  Text slider
   */
  .text-slider {
    line-height: 1.6;
    flex-grow: 1;
    overflow: hidden;

    @include wRule(
      margin-left,
      (
        md: 68,
        sm: 0,
      )
    );
    @include wRule(
      margin-top,
      (
        xl: 67,
        lg: 50,
        md: 0,
        sm: 58,
      )
    );
    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 18,
        md: 16,
        sm: 30,
      )
    );

    &.animation {
      opacity: 0;
      transform: translate3d(0, -20px, 0);
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.is-animated {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: opacity 0.5s ease 0.8s, transform 0.5s ease 0.8s;
    }
  }

  .bottom {
    @include rule(
      display,
      (
        md: flex,
        sm: block,
      )
    );
    @include wRule(
      margin-top,
      (
        md: 44,
        sm: 77,
      )
    );

    overflow: hidden;
    align-items: flex-start;
  }
}
</style>
