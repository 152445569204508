<template>
  <page-section class="mobile-service">
    <template #background>
      <bg-image>
        <picture v-if="data?.background?.mime.includes('image')">
          <source
            v-if="data?.backgroundMobile"
            :srcset="getUrl(data?.backgroundMobile?.url)"
            :type="data?.backgroundMobile?.mime"
            media="(max-width: 767px)"
          />
          <source
            v-if="data?.background"
            :srcset="getUrl(data?.background?.url)"
            :type="data?.background?.mime"
          />
          <img
            :src="getUrl(data?.background?.url)"
            :alt="data?.Title"
          >
        </picture>
        <video
          v-if="data?.background?.mime?.includes('video')"
          :src="getUrl(data?.background?.url)"
          loop
          autoplay
          muted
          playsinline
        >
          <source
            v-if="breakpoint==='sm'"
            media="(min-width: 767px)"
            :srcset="getUrl(data?.backgroundMobile?.url)"
          >
        </video>
      </bg-image>
    </template>
    <template #content>
      <div class="container">
        <div class="content">
          <h3
            class="animation"
            v-observe-visibility="{ callback: animation, once: true}"
          >{{ data.title }}</h3>
          <p
            class="animation"
            v-observe-visibility="{ callback: animation, once: true}"
          >{{ data.describe }}</p>
        </div>
      </div>
    </template>
  </page-section>
</template>

<script>
import PageSection from '@/components/PageSection.vue';
import BgImage from '@/components/BgImage.vue';
import { animationMixin } from '@/components/mixins/animation';
import getUrl from '@/tools/getUrl';

export default {
  name: 'MobileService',
  components: { BgImage, PageSection },
  mixins: [animationMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.mobile-service {
  align-items: flex-end;

  @include rule(
    min-height,
    (
      xl: 100vh,
    )
  );
  @include wRule(
    padding,
    (
      xl: 186 0,
      lg: 136 0,
      md: 133 0,
      sm: 104 0,
    )
  );

  p {
    @include wRule(
      max-width,
      (
        xl: 470,
        lg: 100%,
      )
    );
  }

  .content {
    @include wRule(
      max-width,
      (
        lg: 405,
        md: 334,
        sm: 100%,
      )
    );
  }
}
</style>
