<template>
  <PageSection class="fullpage place-info">
    <template #background>
      <bg-image>
        <picture v-if="content?.BackgroundDesktop?.mime.includes('image')">
          <source
            v-if="content.BackgroundMobile"
            :srcset="getUrl(content?.BackgroundMobile?.url)"
            :type="content?.BackgroundMobile?.mime"
            media="(max-width: 767px)"
          />
          <source
            v-if="content.BackgroundDesktop"
            :srcset="getUrl(content?.BackgroundDesktop?.url)"
            :type="content?.BackgroundDesktop?.mime"
          />
          <img
            :src="getUrl(content?.BackgroundDesktop?.url)"
            alt="@@"
          >
        </picture>
        <video
          v-if="content?.BackgroundDesktop?.mime?.includes('video')"
          :src="getUrl(content?.BackgroundDesktop?.url)"
          loop
          autoplay
          muted
          playsinline
        >
          <source
            v-if="breakpoint==='sm'"
            media="(min-width: 767px)"
            :srcset="getUrl(content?.BackgroundMobile?.url)"
          >
        </video>
      </bg-image>
    </template>
    <template #content>
      <div
        class="container"
        v-observe-visibility="{callback: animation, once: true}"
      >
        <div class="column">
          <transition name="fade-down">
            <div
              class="symbol"
              v-show="inView"
            >
              <Icon name="pin-outline" />
            </div>
          </transition>
          <transition name="fade-down">
            <h3 v-show="inView">{{ content?.Title }}</h3>
          </transition>
          <transition name="fade-down">
            <p v-show="inView">{{ content?.Description }}</p>
          </transition>
          <transition name="fade-down">
            <VButton
              :tag="'button'"
              type="button"
              v-if="content?.ButtonText && content?.ButtonVideo"
              @click.prevent="popupActive = true"
              v-show="inView"
            >
              <template #default>
                {{content?.ButtonText}}
              </template>
            </VButton>
          </transition>
        </div>
      </div>
    </template>
  </PageSection>
  <teleport to="#popups">
    <transition name="fade-slide-up">
      <popup
        v-if="popupActive"
        @modal-closed="popupActive = false"
        :isShown="popupActive"
      >
        <template #content>
          <bg-image class="video">
            <video
              :src="getUrl(content?.ButtonVideo?.url)"
              controls
              playinline
              autoplay
            >
            </video>
          </bg-image>
        </template>
      </popup>
    </transition>
  </teleport>
</template>

<script>
import BgImage from '../../../components/BgImage.vue';
import Icon from '../../../components/Icon.vue';
import PageSection from '../../../components/PageSection.vue';
import VButton from '../../../components/VButton.vue';
import getUrl from '@/tools/getUrl';
import { animationMixin } from '@/components/mixins/animation';
import Popup from '../../../components/Popup.vue';

export default {
  components: {
    PageSection,
    BgImage,
    VButton,
    Icon,
    Popup,
  },
  name: 'PlaceInfo',
  mixins: [animationMixin],
  data() {
    return {
      popupActive: false,
    };
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    base() {
      return {
        Title: this.content?.Title,
        Text: this.content?.Description,
        Button: this.content?.ButtonText,
        Video: this.content?.ButtonVideo,
        Background: this.content?.BackgroundDesktop,
        BackgroundMobile: this.content?.BackgroundMobile,
      };
    },
  },
  methods: {
    getUrl,
  },
};
</script>

<style lang="scss" scoped>
.place-info {
  display: flex;

  @include rule(
    min-height,
    (
      sm: 0,
    )
  );

  @include rule(
    padding,
    (
      xl: pxtovw(220, xl) 0 pxtovh(182, xl),
      lg: pxtovw(160, lg) 0 pxtovh(110, lg),
      // lg: pxtovw(160, lg) 0 pxtovh(139, lg),
      md: pxtovw(140, md) 0 pxtovh(138, md),
      sm: pxtovw(125, sm) 0 pxtovw(112, sm),
    )
  );

  ::v-deep(.page-section__content) {
    display: flex;
  }

  ::v-deep(.container) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  ::v-deep(.icon) {
    @include wRule(
      width,
      (
        xl: 23,
        lg: 16,
        md: 16,
        sm: 23,
      )
    );
    @include wRule(
      height,
      (
        xl: 32,
        lg: 22,
        md: 22,
        sm: 32,
      )
    );
  }

  .symbol {
    &.fade-down-enter-active {
      transition-delay: 0.5s;
    }
  }

  ::v-deep(h3, h1, h2) {
    &.fade-down-enter-active {
      transition-delay: 0.6s;
    }

    &:not(:first-child) {
      @include rule(
        margin-top,
        (
          xl: pxtovh(34, xl),
          lg: pxtovh(20, lg),
          md: pxtovh(20, md),
          sm: pxtovw(32, sm),
        )
      );
    }

    + p {
      &:not(:first-child) {
        @include wRule(
          margin-top,
          (
            xl: pxtovh(89, xl),
            lg: pxtovh(58, lg),
            // lg: pxtovh(68, lg),
            md: pxtovh(38, md),
            sm: pxtovw(48, sm),
          )
        );
      }
    }
  }

  ::v-deep(p) {
    line-height: 1.6;

    @include wRule(
      max-width,
      (
        xl: 495,
        lg: 420,
        md: 370,
        sm: 100%,
      )
    );

    &.fade-down-enter-active {
      transition-delay: 0.7s;
    }
  }

  .v-button {
    &.fade-down-enter-active {
      transition-delay: 0.8s;
    }

    @include rule(
      width,
      (
        sm: 100%,
      )
    );

    @include wRule(
      padding,
      (
        xl: 0 39,
        lg: 0 34,
        md: 0 30,
        sm: 0 30,
      )
    );

    @include wRule(
      margin-bottom,
      (
        xl: 12,
        lg: 6,
        md: 4,
        sm: 13,
      )
    );

    &:not(:first-child) {
      @include wRule(
        margin-top,
        (
          xl: pxtovh(82, xl),
          lg: pxtovh(67, lg),
          md: pxtovh(52, md),
          sm: pxtovw(70, sm),
        )
      );
    }
  }
}
.video {
  width: 100%;
  @include ratio(1920, 1080);

  &::after {
    display: none;
  }
}

::v-deep(.popup__content) {
  @include wRule(
    padding-top,
    (
      xl: 80,
      lg: 30,
      md: 30,
      sm: 30,
    )
  );

  @include wRule(
    padding-bottom,
    (
      xl: 80,
      lg: 30,
      md: 30,
      sm: 30,
    )
  );
}
</style>
