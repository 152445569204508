<template>
  <div class="page-container">
    <breadcrumbs class="breadcrumbs"></breadcrumbs>
    <template v-for="base, index in baseContent" :key="`${base?.Title || 'b'}-${index}`">
      <PlaceInfo  :content="base"/>
    </template>
    <ProjectSlider
      v-if="pageContent?.ProjectSliderActive"
      :data="projectSliderContent"
    />
    <InstrumentsSlider v-if="false" :data="instrumentsContent" />
    <MobileService
      v-if="pageContent?.MobileServiceSectionActive"
      :data="mobileServiceContent"
    />
  </div>
</template>

<script>
import Breadcrumbs from '../../components/Breadcrumbs.vue';
import MobileService from '@/views/Base/components/MobileService.vue';
import ProjectSlider from '@/views/Base/components/ProjectSlider.vue';
import InstrumentsSlider from '@/views/Base/components/InstrumentsSlider.vue';

// API
import api from '@/tools/api';
import PlaceInfo from './components/PlaceInfo.vue';

export default {
  name: 'Locations',
  components: {
    InstrumentsSlider,
    ProjectSlider,
    MobileService,
    Breadcrumbs,
    PlaceInfo,
  },
  data() {
    return {
      pageContent: {},
      baseContent: {},
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
    mobileServiceContent() {
      return {
        title: this.pageContent?.MobileServiceTitle,
        describe: this.pageContent?.MobileServiceContent,
        background: this.pageContent?.MobileServiceBackground,
        backgroundMobile: this.pageContent?.MobileServiceBackgroundMobile,
      };
    },
    projectSliderContent() {
      return {
        title: this.pageContent?.ProjectSliderContent?.[0]?.Title,
        sliderTitle: this.pageContent?.ProjectSliderContent?.[0]?.NavigationTitle,
        slides: this.pageContent?.ProjectSliderContent?.[0]?.Slides,
      };
    },
    instrumentsContent() {
      return {
        title: this.pageContent?.InstrumentsSectionTitle,
        slides: this.pageContent?.Instruments,
      };
    },
    places() {
      return {
        dobrograd: {
          Title: this.pageContent?.DobrogradSectionContent?.DobrogradTitle,
          Text: this.pageContent?.DobrogradSectionContent?.DobrogradText,
          Button: this.pageContent?.DobrogradSectionContent?.DobrogradButtonText,
          Video: this.pageContent?.DobrogradSectionContent?.DobrogradVideo,
          Background: this.pageContent?.DobrogradSectionContent?.DobrogradBackground,
          BackgroundMobile: this.pageContent?.DobrogradSectionContent?.DobrogradBackgroundMobile,
        },
        vnukovo: {
          Title: this.pageContent?.VnukovoSectionContent?.VnukovoTitle,
          Text: this.pageContent?.VnukovoSectionContent?.VnukovoText,
          Background: this.pageContent?.VnukovoSectionContent?.VnukovoBackground,
          BackgroundMobile: this.pageContent?.VnukovoSectionContent?.VnukovoBackgroundMobile,
        },
      };
    },
    currentLang() {
      return this.$store.getters.getLang;
    },
  },
  methods: {
    async getPageContent() {
      this.pageContent = await api('page-base-content', true);
      this.baseContent = await api('bases', false, 'Order');
    },
    setLeadHeight() {
      const observeFor = document.querySelectorAll('.place-info');

      observeFor.forEach((elem) => {
        const el = elem;
        if (this.breakpoint === 'sm') {
          const wH = window.innerHeight;
          el.style.height = `${wH}px`;
        } else {
          el.style.height = '';
        }
      });

      const resizer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const el = entry.target;

          if (this.breakpoint === 'sm') {
            const wH = window.innerHeight;
            el.style.height = `${wH}px`;
          } else {
            el.style.height = '';
          }
        });
      });

      observeFor.forEach((elem) => {
        const el = elem;
        resizer.observe(el);

        if (this.breakpoint === 'sm') {
          const wH = window.innerHeight;
          el.style.height = `${wH}px`;
        } else {
          el.style.height = '';
        }
      });
    },
  },
  watch: {
    currentLang() {
      this.getPageContent();
    },
  },
  beforeMount() {
    this.getPageContent();
  },
  mounted() {
    this.setLeadHeight();
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.mobile-service {
  align-items: flex-end;

  @include rule(
    min-height,
    (
      sm: 100vh,
    )
  );
  @include wRule(
    padding,
    (
      xl: 186 0,
      lg: 136 0,
      md: 133 0,
      sm: 104 0,
    )
  );

  p {
    @include wRule(
      max-width,
      (
        xl: 470,
        lg: 100%,
      )
    );
  }

  .content {
    @include wRule(
      max-width,
      (
        lg: 405,
        md: 334,
        sm: 100%,
      )
    );
  }
}
</style>
